interface Stage {
  stage: string
  url: string
}
export const stages: Stage[] = [
  {
    stage: 'local',
    url: 'https://gridmako-datakeeper-local.conuti.de:10443'
  },
  {
    stage: 'dev',
    url: 'https://dev-gridmaco-datakeeper.conuti.dev'
  },
  {
    stage: 'test',
    url: 'https://test-gridmaco-datakeeper.conuti.dev'
  },
  {
    stage: 'qa',
    url: 'https://qa-gridmaco-datakeeper.conuti.dev'
  },
  {
    stage: 'demo',
    url: 'https://demo-gridmaco-datakeeper.conuti.dev'
  }
]
