import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import keyCloakLogin from './skeleton/auth/Keycloak'
import reportWebVitals from './reportWebVitals'

function initReact (): void {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(
        <App/>
  )
}

void (async function (): Promise<void> {
  await keyCloakLogin()
  initReact()
})()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log)
