import React from 'react'
import { createBrowserRouter } from 'react-router-dom'
import Layout from 'skeleton/Layout'
import ErrorPage from 'skeleton/ErrorPage'
import Deployment from 'applications/camunda/deployment/Deployment'
import EbdTest from 'applications/camunda/CamundaEbdTest/EbdTest'
import Dashboard from 'applications/dashboard/Dashboard'
import DataKeeper from 'applications/dataKeeper/dataKeeper'

const Router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'deployment',
        element: <Deployment />
      },
      {
        path: 'ebd-test',
        element: <EbdTest />
      },
      {
        path: 'data-keeper',
        element: <DataKeeper />
      }
    ]
  }
])

export default Router
