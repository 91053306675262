import * as React from 'react'
import { type ReactElement } from 'react'
import { getDataFromDK } from 'services/DataKeeperService'
import { DataKeeperForm } from 'applications/dataKeeper/dataKeeperForm'
import { dataKeeperStore } from 'applications/dataKeeper/dataKeeperStore'
export default function DataKeeperSearch (): ReactElement {
  const businessKey = dataKeeperStore((state: any) => state.businessKey)
  const jsonPath = dataKeeperStore((state: any) => state.jsonPath)
  const processDate = dataKeeperStore((state: any) => state.processDate)
  const setDataKeeperDataInbound = dataKeeperStore((state: any) => state.setDataKeeperDataInbound)
  const setDataKeeperDataOutbound = dataKeeperStore((state: any) => state.setDataKeeperDataOutbound)
  const setHideInputData = dataKeeperStore((state: any) => state.setHideInputData)
  const setHideOutputData = dataKeeperStore((state: any) => state.setHideOutputData)
  const stageUrl = dataKeeperStore((state) => state.stageUrl)

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    void searchData().catch(() => {
      console.error('failed to search data in keeper')
    })
  }

  const searchData = async (): Promise<any> => {
    try {
      const response: Response = await getDataFromDK(stageUrl, businessKey, jsonPath, processDate)
      const dataKeeperData = await response.json()

      setDataKeeperDataInbound(JSON.stringify(dataKeeperData.data.INBOUND, null, 4))
      setDataKeeperDataOutbound(JSON.stringify(dataKeeperData.data.OUTBOUND, null, 4))
      setHideInputData(false)
      setHideOutputData(false)
    } catch (reason) {
      console.error(reason)
    }
  }

  return (
    <DataKeeperForm handleSubmit={handleSubmit} />
  )
}
