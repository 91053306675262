import React, { type ReactNode } from 'react'
import { Helmet } from 'react-helmet'
export default function MetaData (): ReactNode {
  return (
    <Helmet>
      <title>Conuti applications dashboard</title>
      <meta charSet="utf-8" />
      <meta name="theme-color" content="#000000" />
      <meta name="description" content="A Dashboard for Conutis applications" />
    </Helmet>
  )
}
