import React, { type ReactElement, useEffect } from 'react'
import {
  Divider,
  FormControl, FormControlLabel, FormLabel,
  Grid,
  InputLabel,
  MenuItem, Radio, RadioGroup,
  Select
} from '@mui/material'
import DataKeeperSearch from 'applications/dataKeeper/dataKeeperSearch'
import DataKeeperSave from 'applications/dataKeeper/dataKeeperSave'
import { dataKeeperStore } from 'applications/dataKeeper/dataKeeperStore'
import { stages } from 'applications/dataKeeper/stages'

export default function DataKeeper (): ReactElement {
  const setBusinessKey = dataKeeperStore((state: any) => state.setBusinessKey)
  const setJsonPath = dataKeeperStore((state: any) => state.setJsonPath)
  const setProcessDate = dataKeeperStore((state: any) => state.setProcessDate)
  const setDataKeeperDataInbound = dataKeeperStore((state: any) => state.setDataKeeperDataInbound)
  const setDataKeeperDataOutbound = dataKeeperStore((state: any) => state.setDataKeeperDataOutbound)

  const action = dataKeeperStore((state) => state.action)
  const setAction = dataKeeperStore((state) => state.setAction)

  const stageUrl = dataKeeperStore((state) => state.stageUrl)
  const setStageUrl = dataKeeperStore((state) => state.setStageUrl)

  useEffect(() => {
    setBusinessKey('')
    setJsonPath('')
    setProcessDate('')
    setDataKeeperDataInbound('')
    setDataKeeperDataOutbound('')
  }, [action])

  return (
    <>
      <Grid container>
        <Grid item xs={8}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={4}>
              <FormControl>
                <FormLabel id="search-store-radiogroup">search or store data in keeper</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="search-store-radiogroup"
                  name="search-store-radiogroup"
                  value={action}
                  onChange={(e) => { setAction(e.target.value) }}
                >
                  <FormControlLabel value={'search'} control={<Radio color={'success'} />} label="search data in keeper" />
                  <FormControlLabel value={'store'} control={<Radio color={'success'} />} label="store data in keeper" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel id="choose-stage-label">choose stage</InputLabel>
                <Select
                  labelId="choose-stage-label"
                  id="choose-stage-select"
                  value={stageUrl}
                  label="Stage"
                  onChange={({ target: { value } }) => { setStageUrl(value) }}
                >
                  {stages.map((stage, index) =>
                      <MenuItem key={index} value={stage.url}>{stage.stage}: {stage.url}</MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={8}>
        </Grid>
      </Grid>
      <Divider />
      {
        action === 'search'
          ? <DataKeeperSearch />
          : <DataKeeperSave />
      }
    </>
  )
}
