import React, { type ReactNode } from 'react'
import { FormControl, Grid } from '@mui/material'
import JsonInput from 'applications/camunda/CamundaEbdTest/JsonInput'
import BusinessKey from 'applications/camunda/CamundaEbdTest/BusinessKey'
import Button from '@mui/material/Button'

export default function EbdTest (): ReactNode {
  const startTest = (): void => {
    console.log('start test')
  }

  return (
    <Grid container spacing={2} padding={2} data-testid="ebd_test_page">
      <BusinessKey />
      <JsonInput />
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Button variant="contained" onClick={() => { startTest() }}>start test</Button>
        </FormControl>
      </Grid>
    </Grid>
  )
}
