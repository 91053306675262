import * as React from 'react'
import { type ReactElement } from 'react'
import {
  deleteInboundInDK, deleteOutboundInDK, setInboundInDK, setOutboundInDK
} from 'services/DataKeeperService'
import { DataKeeperForm } from 'applications/dataKeeper/dataKeeperForm'
import { dataKeeperStore } from 'applications/dataKeeper/dataKeeperStore'

export default function DataKeeperSave (): ReactElement {
  const businessKey = dataKeeperStore((state: any) => state.businessKey)
  const dataKeeperDataInbound = dataKeeperStore((state: any) => state.dataKeeperDataInbound)
  const dataKeeperDataOutbound = dataKeeperStore((state: any) => state.dataKeeperDataOutbound)

  const storeData = async (): Promise<void> => {
    await handleDKResponse(
      deleteInboundInDK(businessKey)
    )

    await handleDKResponse(
      deleteOutboundInDK(businessKey)
    )

    await handleDKResponse(
      setInboundInDK(businessKey, dataKeeperDataInbound)
    )

    await handleDKResponse(
      setOutboundInDK(businessKey, dataKeeperDataOutbound)
    )
  }

  const handleDKResponse = async (
    promise: Promise<Response>
  ): Promise<Response> => {
    return await promise
  }

  const handleSubmit = (event: { preventDefault: () => void }): void => {
    event.preventDefault()
    void storeData().catch()
  }

  return (
    <DataKeeperForm handleSubmit={handleSubmit} />
  )
}
