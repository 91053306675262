import React, { type ReactNode } from 'react'
import { FormControl, Grid, TextField } from '@mui/material'

export default function JsonInput (): ReactNode {
  return (
    <>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            id="inbound"
            label="INBOUND"
            multiline
            rows={4}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <TextField
            id="oubound"
            label="OUTBOUND"
            multiline
            rows={4}
          />
        </FormControl>
      </Grid>
    </>
  )
}
