import { type KeycloakConfig } from 'keycloak-js'
import { Stage, stageConfigMap } from './config'

type Host = string

interface Config {
  apiUrl: string
  dataKeeperUrl: string
  keyCloak: KeycloakConfig
}

export type StageConfigMap = Map<Stage, Config>
type StageHostMap = Map<Stage, Host>

const stageHostMap: StageHostMap = new Map([
  [Stage.Local, 'http://localhost:3000'],
  [Stage.Dev, 'https://dev-gridmaco-backoffice-web.conuti.dev'],
  [Stage.Test, 'https://test-gridmaco-backoffice-web.conuti.dev']
])

const determineStage = (currentHost: Host): Stage => {
  for (const [stage, host] of stageHostMap) {
    if (host.includes(currentHost)) {
      return stage
    }
  }

  throw new Error('Unknown host: ' + currentHost)
}

const determineConfig = (stage: Stage): Config => {
  const config = stageConfigMap.get(stage)
  if (config === undefined) {
    throw new Error('Unknown config: ' + stage)
  }
  return config
}
const currentHost = window.location.hostname
const currentStage = determineStage(currentHost)
const config = determineConfig(currentStage)

console.log({ currentStage: Stage[currentStage], currentConfig: config })

export default config
