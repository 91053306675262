import React, { type ReactNode } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Link as RouterLink } from 'react-router-dom'
import { type SvgIconProps } from '@mui/material/'

interface LinkProps {
  Icon: (props: SvgIconProps) => ReactNode
  id: string
  sx?: any
  to: string
  text: string
}

export default function Link ({ Icon, sx, to, text, id }: LinkProps): ReactNode {
  return (
        <ListItemButton sx={sx} component={RouterLink} to={to} data-testid={id} id={id}>
            <ListItemIcon>
                <Icon />
            </ListItemIcon>
            <ListItemText primary={text} />
        </ListItemButton>
  )
}
