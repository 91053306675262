import { create } from 'zustand'

interface State {
  businessKey: string
  jsonPath: string
  processDate: string
  dataKeeperDataInbound: string
  dataKeeperDataOutbound: string
  action: string
  hideInputData: boolean
  hideOutputData: boolean
  stageUrl: string
  setBusinessKey: (businessKey: string) => void
  setJsonPath: (jsonPath: string) => void
  setProcessDate: (processDate: string) => void
  setDataKeeperDataInbound: (dataKeeperDataInbound: string) => void
  setDataKeeperDataOutbound: (dataKeeperDataOutbound: string) => void
  setAction: (typeIsSearch: string) => void
  setHideInputData: (hideInputData: boolean) => void
  setHideOutputData: (hideOutputData: boolean) => void
  setStageUrl: (stageUrl: string) => void
}
export const dataKeeperStore = create<State>((set) => ({
  businessKey: '',
  jsonPath: '',
  processDate: '',
  dataKeeperDataInbound: '',
  dataKeeperDataOutbound: '',
  action: 'search',
  hideInputData: true,
  hideOutputData: true,
  stageUrl: '',
  setBusinessKey: (businessKey) => { set({ businessKey }) },
  setJsonPath: (jsonPath) => { set({ jsonPath }) },
  setProcessDate: (processDate) => { set({ processDate }) },
  setDataKeeperDataInbound: (dataKeeperDataInbound) => { set({ dataKeeperDataInbound }) },
  setDataKeeperDataOutbound: (dataKeeperDataOutbound) => { set({ dataKeeperDataOutbound }) },
  setAction: (action) => { set({ action }) },
  setHideInputData: (hideInputData) => { set({ hideInputData }) },
  setHideOutputData: (hideOutputData) => { set({ hideOutputData }) },
  setStageUrl: (stageUrl) => { set({ stageUrl }) }
}))
