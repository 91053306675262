import { type StageConfigMap } from 'config'

export enum Stage {
  Local,
  Dev,
  Test,
}
const keyCloak = {
  url: 'https://dev-gridmaco-mcs.conuti.dev/auth',
  realm: 'gridmako',
  clientId: 'gridmako-backoffice'
}
export const stageConfigMap: StageConfigMap = new Map([
  [Stage.Local, {
    apiUrl: 'https://gridmako-backoffice-api-local.conuti.de:10443',
    dataKeeperUrl: 'https://gridmako-datakeeper-local.conuti.de:10443/api/datakeeper',
    keyCloak
  }],
  [Stage.Dev, {
    apiUrl: 'https://dev-gridmaco-backoffice-api.conuti.dev/api/datakeeper',
    dataKeeperUrl: 'https://dev-gridmaco-datakeeper.conuti.dev',
    keyCloak
  }],
  [Stage.Test, {
    apiUrl: 'https://test-gridmaco-backoffice-api.conuti.dev',
    dataKeeperUrl: 'https://test-gridmaco-datakeeper.conuti.dev/api/datakeeper',
    keyCloak
  }]
])
