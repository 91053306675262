import * as React from 'react'
import { type ReactElement } from 'react'
import { FormControl, Grid, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { dataKeeperStore } from 'applications/dataKeeper/dataKeeperStore'

interface IProps {
  handleSubmit: any
}

export const DataKeeperForm: React.FC<IProps> = ({ handleSubmit }): ReactElement => {
  const businessKey = dataKeeperStore((state: any) => state.businessKey)
  const jsonPath = dataKeeperStore((state: any) => state.jsonPath)
  const processDate = dataKeeperStore((state: any) => state.processDate)
  const dataKeeperDataInbound = dataKeeperStore((state: any) => state.dataKeeperDataInbound)
  const dataKeeperDataOutbound = dataKeeperStore((state: any) => state.dataKeeperDataOutbound)
  const hideInputData = dataKeeperStore((state: any) => state.hideInputData)
  const hideOutputData = dataKeeperStore((state: any) => state.hideOutputData)
  const action = dataKeeperStore((state) => state.action)
  const setBusinessKey = dataKeeperStore((state: any) => state.setBusinessKey)
  const setJsonPath = dataKeeperStore((state: any) => state.setJsonPath)
  const setProcessDate = dataKeeperStore((state: any) => state.setProcessDate)
  const setDataKeeperDataInbound = dataKeeperStore((state: any) => state.setDataKeeperDataInbound)
  const setDataKeeperDataOutbound = dataKeeperStore((state: any) => state.setDataKeeperDataOutbound)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={8}>
          <Grid container spacing={2} padding={2}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  required={true}
                  id="businessKey"
                  label="BusinessKey"
                  variant="outlined"
                  value={businessKey}
                  onChange={({ target: { value } }) => { setBusinessKey(value) }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  id="processDate"
                  label="ProcessDate"
                  variant="outlined"
                  value={processDate}
                  onChange={({ target: { value } }) => { setProcessDate(value) }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3} hidden={action !== 'search' ?? true}>
              <FormControl fullWidth>
                <TextField
                  required={false}
                  id="jsonPath"
                  label="jsonPath"
                  variant="outlined"
                  placeholder={'.stammdaten'}
                  value={jsonPath}
                  onChange={({ target: { value } }) => { setJsonPath(value) }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={action !== 'search' ? 6 : 3}>
              <Button type="submit" variant="contained" size="large" fullWidth>
                {action !== 'search' ? 'store data in keeper' : 'search data in keeper'}
              </Button>
            </Grid>
            <Grid item xs={6} hidden={hideInputData}>
              <TextField
                id="jsonDataInbound"
                label="INBOUND"
                multiline
                rows={40}
                fullWidth
                value={dataKeeperDataInbound}
                onChange={(text) => {
                  setDataKeeperDataInbound(text.target.value)
                }}
              />
            </Grid>
            <Grid item xs={6} hidden={hideOutputData}>
              <TextField
                id="jsonDataOutbound"
                label="OUTBOUND"
                multiline
                rows={40}
                fullWidth
                value={dataKeeperDataOutbound}
                onChange={(text) => {
                  setDataKeeperDataOutbound(text.target.value)
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
        </Grid>
      </Grid>
    </form>
  )
}
