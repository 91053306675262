import React, { type ReactNode } from 'react'
import { FormControl, Grid, TextField } from '@mui/material'
import Button from '@mui/material/Button'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'

export default function BusinessKey (): ReactNode {
  const [businessKey, setBusinessKey] = useState('')
  const generateBusinessKey = (): void => {
    setBusinessKey(uuidv4())
  }

  return (
    <>
      <Grid item xs={10}>
        <FormControl fullWidth>
          <TextField
            id="businessKey"
            label="BusinessKey"
            variant="outlined"
            value={businessKey}
          />
        </FormControl>
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <Button variant="contained" onClick={() => { generateBusinessKey() }}>generate</Button>
        </FormControl>
      </Grid>
    </>
  )
}
