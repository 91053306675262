import React, { type ReactNode } from 'react'
import { RouterProvider } from 'react-router-dom'
import Router from 'skeleton/Routes'
import MetaData from 'skeleton/MetaData'

export default function Home (): ReactNode {
  return (
    <>
      <MetaData />
      <RouterProvider router={Router} />
    </>
  )
}
