import { createTheme } from '@mui/material'

export const Theme = createTheme({
  typography: {
    fontFamily: 'sans-serif "Grandstander"'
  },
  palette: {
    mode: 'light',
    primary: {
      main: '#164656',
      light: '#7ec7be'
    },
    secondary: {
      main: '#f7b85a'
    },
    error: {
      main: '#ea5837'
    },
    background: {
      default: '#fdf1ec'
    },
    text: {
      primary: '#542904'
    }
  }
})
