import React, { type ReactElement } from 'react'
import ProcessTable from 'applications/camunda/deployment/ProcessTable'

export default function Deployment (): ReactElement {
  return (
      <div data-testid="deployment_page">
        <ProcessTable />
      </div>
  )
}
