import config from 'config'

const apiURL = config.dataKeeperUrl

export const deleteInboundInDK = async (businessKey: string): Promise<any> => {
  const requestBody = {
    businessKey,
    dataSource: 'INBOUND',
    jsonPath: '.',
    processDate: null
  }

  const fetchOptions = {
    method: 'DELETE',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(requestBody)
  }

  return await fetch(apiURL, fetchOptions)
}

export const deleteOutboundInDK = async (businessKey: string): Promise<any> => {
  const requestBody = {
    businessKey,
    dataSource: 'OUTBOUND',
    jsonPath: '.',
    processDate: null
  }

  const fetchOptions = {
    method: 'DELETE',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(requestBody)
  }

  return await fetch(apiURL, fetchOptions)
}

export const setInboundInDK = async (businessKey: string, inbound: string): Promise<any> => {
  const requestBody = {
    businessKey,
    dataSource: 'INBOUND',
    jsonPath: '.',
    processDate: null,
    data: JSON.parse(inbound)
  }

  const fetchOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(requestBody)
  }

  return await fetch(apiURL, fetchOptions)
}

export const setOutboundInDK = async (businessKey: string, outbound: string): Promise<any> => {
  const requestBody = {
    businessKey,
    dataSource: 'OUTBOUND',
    jsonPath: '.',
    processDate: null,
    data: JSON.parse(outbound)
  }

  const fetchOptions = {
    method: 'POST',
    headers: { 'Content-type': 'application/json; charset=UTF-8' },
    body: JSON.stringify(requestBody)
  }

  return await fetch(apiURL, fetchOptions)
}

export const getDataFromDK = async (
  stageUrl: string,
  businessKey: string,
  jsonPath: string,
  processDate: string
): Promise<Response> => {
  const baseUrl = ((stageUrl !== '') ? `${stageUrl}/api/datakeeper` : apiURL)
  jsonPath = (jsonPath === '') ? '.' : jsonPath
  processDate = (processDate === '') ? '' : processDate

  let url = `${baseUrl}?businessKey=${businessKey}&jsonPath=${jsonPath}`

  if (processDate !== '') {
    url += `&processDate=${processDate}`
  }

  return await fetch(url, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json; charset=UTF-8'
    }
  })
}
