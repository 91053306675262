import React, { type ReactNode } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import DashboardIcon from '@mui/icons-material/Dashboard'
import LayersIcon from '@mui/icons-material/Layers'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import Collapse from '@mui/material/Collapse'
import List from '@mui/material/List'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import StorageIcon from '@mui/icons-material/Storage'
import Link from 'skeleton/navigation/Link'

export default function Navigation (): ReactNode {
  const [open, setOpen] = React.useState(true)

  return (
    <List component="nav">
      <Link Icon={DashboardIcon} to="/" text="Dashboard" id="dashboard_link"/>
      <ListItemButton onClick={() => { setOpen(!open) }}>
        <ListItemIcon>
          <LayersIcon/>
        </ListItemIcon>
        <ListItemText primary="Camunda"/>
        {open ? <ExpandLess/> : <ExpandMore/>}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            <Link Icon={InboxIcon} sx={{ pl: 4 }} to="/deployment" text="Deployment" id="deployment_link"/>
            <Link Icon={InboxIcon} sx={{ pl: 4 }} to="/ebd-test" text="Ebd Test" id="ebd_test_link"/>
        </List>
      </Collapse>
      <Link Icon={StorageIcon} to="/data-keeper" text="Datakeeper" id="datakeeper_link"/>
    </List>
  )
}
