import Keycloak from 'keycloak-js'
import store from 'store'
import config from 'config'

const keycloak = new Keycloak(config.keyCloak)
const updateToken = (): void => {
  setInterval((): void => {
    keycloak.updateToken(60).then((refreshed): void => {
      if (refreshed) {
        console.info('Token refreshed' + refreshed)
        store.token = keycloak.token ?? 'no-token'
      } else {
        console.warn('Token not refreshed')
      }
    }).catch(() => {
      console.error('Failed to refresh token')
    })
  }, 6000)
}
export default async (): Promise<boolean> => {
  try {
    await keycloak.init({ onLoad: 'login-required' })
    // await keycloak.init({ onLoad: 'login-required', redirectUri: 'http://localhost:3000/' });
    store.token = keycloak.token ?? 'no-token'
    updateToken()

    return true
  } catch (e) {
    console.log(e)
  }
  return false
}
