import React, { type ReactNode } from 'react'
import { CssBaseline, ThemeProvider as MUIThemeProvider } from '@mui/material'
import { Theme } from './Theme'

const ThemeProvider = ({ children }: { children: ReactNode }): ReactNode => {
  return (
    <MUIThemeProvider theme={Theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

export default ThemeProvider
